/**
 * Url utility methods adapted from axios internal helpers.
 */

function isAbsoluteURL(url: string): boolean {
  // A URL is considered absolute if it begins with "<scheme>://" or "//" (protocol-relative URL).
  // RFC 3986 defines scheme name as a sequence of characters beginning with a letter and followed
  // by any combination of letters, digits, plus, period, or hyphen.
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(url);
}

export function combineURLs(baseURL: string = '', relativeURL: string = '') {
  if (!relativeURL) return baseURL;
  if (!baseURL || isAbsoluteURL(relativeURL)) return relativeURL;

  return `${baseURL.replace(/\/+$/, '')}/${relativeURL.replace(/^\/+/, '')}`;
}

export function isSameOriginUrl(url: string) {
  if (!isAbsoluteURL(url)) return true;

  const isProtocolRelative = url.startsWith('//');
  try {
    const fullURL = new URL(isProtocolRelative ? `${window.location.protocol}${url}` : url);
    return fullURL.host === window.location.host;
  } catch (error) {
    // if it's not an absolute URL, and we can't parse a URL from it, it's
    // likely a data URI
  }
  return true;
}
