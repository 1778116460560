/* eslint no-console: 0 */
import { datadogLogs, HandlerType, StatusType } from '@datadog/browser-logs';

import expectedNetworkError from '@/shared/logger/expectedNetworkError';
import scrubSensitiveData from '@/shared/logger/scrubSensitiveData';

let logsClient;

class Logger {
  constructor({
    name = 'geppetto-ui-sdk',
    app,
    logger,
    datadog,
  }) {
    this._name = name;
    this._level = Object.values(StatusType).includes(logger.defaultLevel) ? logger.defaultLevel : StatusType.debug;

    this._debug = app.debug;
    this._ignoreMarker = logger.ignoreEventMarker;

    if (logger.datadog) {
      logsClient = datadogLogs;

      logsClient.init({
        clientToken: datadog.clientToken,
        forwardErrorsToLogs: logger.datadog,
        service: app.name.toLowerCase().replace(/\s/g, '-'),
        env: app.environment,
        version: app.version,

        beforeSend: (event) => { // eslint-disable-line consistent-return
          // prevent errors being sent to DD twice because they're sent directly, but then sent
          // again when the error is shown in the browser console
          if (event.origin === 'console' && event.message.includes(this._ignoreMarker)) return false;

          if (scrubSensitiveData(event) === false) return false;

          if (expectedNetworkError(event)) return false;
        },
      });

      // set global logger context to enable service on forwarded error
      logsClient.setGlobalContextProperty('deployment', app.deploymentBranch);
    }

    this.setToConsole(logger.console);
    this.setToDatadog(logger.datadog);

    this._contextMutators = new Set();

    // Create logger instance in case we want to add context or enable toDatadog later
    if (this._toDatadog) {
      this._logger = logsClient.getLogger(this._name) || logsClient.createLogger(this._name, {
        level: this._level,
        destination: HandlerType.http, // if Datadog logs enabled, always ship to 'http'
      });
    }
  }

  install(vueApp) {
    // eslint-disable-next-line no-param-reassign
    vueApp.config.globalProperties.logger = this;
  }

  setLevel(status) {
    this._level = Object.values(StatusType).includes(status) ? status : StatusType.debug;

    if (this._toDatadog) {
      this._logger.setLevel(this._level);
    }
  }

  setToConsole(toConsole) {
    this._toConsole = toConsole;
  }

  setToDatadog(toDatadog) {
    this._toDatadog = toDatadog;
  }

  level() {
    return this._level;
  }

  addContext(key, value) {
    if (this._toDatadog) {
      this._logger.addContext(key, value);
    }
  }

  removeContext(key) {
    if (this._toDatadog) {
      // This isn't documented or officially supported but it does cause attributes to not arrive in Datadog
      this._logger.addContext(key, undefined);
    }
  }

  addGlobalContext(key, value) {
    if (this._toDatadog) {
      logsClient.setGlobalContextProperty(key, value);
    }
  }

  removeGlobalContext(key) {
    if (this._toDatadog) {
      // This isn't documented or officially supported but it's in the package src
      logsClient.removeGlobalContextProperty(key);
    }
  }

  addContextMutator(fn) {
    if (typeof fn !== 'function') return;

    this._contextMutators.add(fn);
  }

  removeContextMutator(fn) {
    if (typeof fn !== 'function') return false;

    return this._contextMutators.delete(fn);
  }

  applyContextMutators(context) {
    return [...this._contextMutators].reduce((ctx, mutator) => mutator(ctx), context);
  }

  log(message, context = {}, status = 'debug', ...args) {
    if (this._toConsole) {
      const prefixedMessage = this._toDatadog && status === 'error' ? `${this._ignoreMarker} ${message}` : message;
      this.logToConsole(status, prefixedMessage, context, ...args);
    }

    // Only send valid statuses to Datadog
    if (this._toDatadog && Object.values(StatusType).includes(status)) {
      // Copy so we can transform some standard fields
      const contextToSend = { ...context };
      if (contextToSend.duration) {
        contextToSend.duration *= 1e6; // convert ms to nanoseconds
      }
      this.logToDatadog(status, message, this.applyContextMutators(contextToSend));
    }
  }

  // Allow multiple arguments to be passed to logToConsole to avoid having to
  // always expand a container object
  // eslint-disable-next-line class-methods-use-this
  logToConsole(status, ...args) {
    // Errors should be displayed in console using console.error to enable stack traces
    // Dont use console.debug or console.info because they don't print!
    const consoleMethod = (typeof console[status] === 'function' && !['debug', 'info'].includes(status)) ? status : 'log';

    // In debug mode (local dev) send all args to the console
    // When not in debug mode, FullStory and others intercept console logs and tries
    // to serialise them, leading to infinite loops for Vue instances. In this case only send
    // the first two args: message and context, which should be serialisable
    const safeArgs = (!this._debug) ? args.slice(0, 2) : args;
    if (consoleMethod === 'log') safeArgs.unshift(`${status}:\t`);
    console[consoleMethod](...safeArgs);
  }

  logToDatadog(status, message, context) {
    // format any errors for datadog display and whitelist non-sensitive properties
    if (context.error) {
      // eslint-disable-next-line no-param-reassign
      context.error = {
        message: context.error.message,
        kind: context.error.kind || context.error.name,
        stack: context.error.stack,
        // network errors from geppetto back-end will have more detail here
        // TODO: review whether these leak sensitive detail in practice
        ...(context.error.apiError && { apiError: context.error.apiError }),
      };
    }

    const messageStatus = Object.values(StatusType).includes(status) ? status : StatusType.debug;
    const messageContext = (typeof context === 'object') ? context : { payload: context };

    try {
      // Datadog log shipping will fail if the included object can't be serialised, for example
      // if we try to send a VueComponent which contains circular references.
      // This could be VERY BAD because the associated log event will never appear in Datadog logs
      const serializeContext = JSON.stringify(messageContext); // eslint-disable-line @typescript-eslint/no-unused-vars
      this._logger.log(message, messageContext, messageStatus);
    } catch (error) {
      // If an unserializable object was logged, we should warn the developer but still ship a thin log
      console.warn('Unable to serialize context, context will not be sent to Datadog:', message, messageContext);
      this._logger.log(message, {}, messageStatus);
    }
  }

  // console-only "trace" method
  trace(message, context, ...args) {
    this.log(message, context, 'trace', ...args);
  }

  debug(message, context, ...args) {
    this.log(message, context, 'debug', ...args);
  }

  info(message, context, ...args) {
    this.log(message, context, 'info', ...args);
  }

  warn(message, context, ...args) {
    this.log(message, context, 'warn', ...args);
  }

  error(message, context = {}, ...args) {
    this.log(message, context, 'error', ...args);
  }
}

export default Logger;
